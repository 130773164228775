<template>
  <div class="footer-wrapper">
    <div class="footer-wrapper__div" id="selfPlacedFooter">
      <div
        @click="previousUnit"
        class="footer-wrapper__content left"
        :class="{
          disabled: !showHeaderNav || (isFirstSequence && isFirstUnit)
        }"
      >
        <img
          class="footer-wrapper__content--image arrow"
          src="@/assets/images/module-list/arrow-left.svg"
        />
        <span>{{ $t("general.back") }}</span>
        <img
          class="footer-wrapper__content--image"
          :src="getUnitIcon(getPreviousUnit.type, false)"
        />
      </div>

      <div
        v-if="!getTimedAssessmentFlag"
        @click="nextUnit($route.params.id)"
        class="footer-wrapper__content right"
      >
        <img
          class="footer-wrapper__content--image"
          :src="
            isFinish
              ? require('@/assets/images/module-list/finish.svg')
              : getUnitIcon(getNextUnit?.type, false)
          "
        />
        <span>{{
          isFinish ? $t("vle.timed_assessment.finish") : $t("general.next")
        }}</span>
        <img
          class="footer-wrapper__content--image arrow"
          src="@/assets/images/module-list/arrow-right.svg"
        />
      </div>
      <div
        v-else
        v-b-modal="TIMED_ASSESSMENT_MODAL_IDS.CONFIRMATION_MODAL"
        class="footer-wrapper__content right"
      >
        <img
          class="footer-wrapper__content--image"
          :src="getUnitIcon(getNextUnit?.type, false)"
        />
        <span>{{ "Submit Assessment" }}</span>
        <img
          class="footer-wrapper__content--image arrow"
          src="@/assets/images/module-list/arrow-right.svg"
        />
      </div>
    </div>

    <Modals
      @onSubmit="manualSubmitAssessment"
      @onFinish="nextUnit($route.params.id)"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Modals from "./TimedAssessment/Modals.vue";

// mixins
import nextUnit from "@/components/SelfPaced/mixins/nextUnit.js";
import previousUnit from "@/components/SelfPaced/mixins/previousUnit.js";
import timedAssessment from "./mixins/timedAssessment";
import userLayout from "./mixins/userLayout";
import TIMED_ASSESSMENT_MODAL_IDS from "./TimedAssessment/constants";
export default {
  components: { Modals },
  mixins: [nextUnit, previousUnit, userLayout, timedAssessment],
  data() {
    return {
      TIMED_ASSESSMENT_MODAL_IDS: {}
    };
  },
  created() {
    this.TIMED_ASSESSMENT_MODAL_IDS = TIMED_ASSESSMENT_MODAL_IDS;
  },
  computed: {
    ...mapGetters([
      "showHeaderNav",
      "isFirstSequence",
      "isFirstUnit",
      "isLastUnit",
      "isLastSequence",
      "getNextUnit",
      "getPreviousUnit",
      "getCurrentUnit",
      "getTimedAssessmentFlag",
      "getCurrentSequenceId",
      "isCurrentUnitCompleted"
    ]),
    isFinish() {
      return this.isLastUnit && this.isLastSequence;
    }
  },
  methods: {
    manualSubmitAssessment() {
      this.$bvModal.hide(this.TIMED_ASSESSMENT_MODAL_IDS.CONFIRMATION_MODAL);
      this.$store.commit("SET_SHOW_FULL_PAGE_LOADER", true);
      this.submitAssessment().then(() => {
        this.$store.commit("SET_SHOW_FULL_PAGE_LOADER", false);
        // check for the current unit completion status from server
        // if complete then show success modal
        // else show incomplete modal
        if (this.isCurrentUnitCompleted) {
          this.$bvModal.show(this.TIMED_ASSESSMENT_MODAL_IDS.SUCCESS_MODAL);
        } else {
          this.$bvModal.show(this.TIMED_ASSESSMENT_MODAL_IDS.INCOMPLETE_MODAL);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
$footer-position: 50px;
.footer-wrapper {
  .footer-wrapper__div {
    @include horizontal-space-between;
    margin-top: 36px;
    background: $brand-primary-100;
    border-radius: 100px;
    height: 48px;
    padding: 12px 20px;
    .footer-wrapper__content {
      cursor: pointer;
      @include flex-horizontal-center;
      &:hover {
        span {
          color: $brand-primary-400;
        }
      }
      .footer-wrapper__content--image {
        width: 17.5px;
        height: 17.5px;
        &.arrow {
          width: 11px;
          height: 13px;
        }
      }
      &.left {
        .footer-wrapper__content--image {
          margin: 0 12px;
          &.arrow {
            margin-right: 14px;
          }
        }
      }
      &.right {
        .footer-wrapper__content--image {
          margin: 0 12px;
          &.arrow {
            margin-left: 14px;
          }
        }
      }
      &:hover {
        color: $brand-primary-400;
        .footer-wrapper__content--image {
          filter: invert(35%) sepia(36%) invert(37%) sepia(58%) saturate(5230%)
            hue-rotate(198deg) brightness(104%) contrast(104%);
        }
      }
      &:active {
        color: $brand-primary;
      }
      span {
        @include label-large($brand-primary);
      }
    }
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
